<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Master</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <div class="pos intro-y grid gap-5 my-8">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-for="(master,index) in masters" :key="index">
          <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="master.to"
          >
              <div class="font-medium text-base text-center">{{ master.name }}</div>
          </router-link>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Users</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <div class="pos intro-y grid gap-5 my-8">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-for="(master,index) in users" :key="index">
          <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="master.to"
          >
              <div class="font-medium text-base text-center">{{ master.name }}</div>
          </router-link>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import store from '@/store'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const masters = [
      {
        name: 'Import Station',
        to: { name: 'import-station-master' },
        // access: 103
      },
      {
        name: 'Measurements',
        to: {name: 'uqc-master'},
        // access: 104
      },
      {
        name: 'Raw Material',
        to: { name: 'product-master' },
        // access: 105
      },
      {
        name: 'Raw Material Description',
        to: { name: 'product-material-master' },
        // access: 106
      },
      {
        name: 'Final Goods',
        to: { name: 'size-master' },
        // access: 107
      },
      {
        name: 'TAX',
        to: { name: 'tax-master' },
        // access: 108
      },
      {
        name: 'BOM',
        to: { name: 'consumption-master' },
        // access: 109
      },
      {
        name: 'Purchase Company',
        to: { name: 'purchase-companey-master' },
        // access: 110
      },
      {
        name: 'Outward Company',
        to: { name: 'outward-companey-master' },
        // access: 111
      },
      {
        name: 'Currency',
        to: { name: 'currency-master' },
        // access: 111
      }
    ]
    const users = [
      {
        name: 'Manager',
        to: { name: 'manager-list' },
        // access: 103
      },
    ]
    // const accesses = computed(() => store.state.user.actions)
    return {
      masters,
      users
      // accesses,
    }
  }
})
</script>
