<template>
<div>
    <DarkModeSwitcher />
    <MobileMenu />
    <div class="flex">
        <!-- BEGIN: Side Menu -->
        <nav class="side-nav">
            <!-- BEGIN: Logo -->
            <router-link :to="{ name: 'dashboard-view' }" tag="a" class="intro-x flex items-center pl-5 pt-4">
                <img alt="Icewall Tailwind HTML Admin Template" class="w-6" src="@/assets/images/logo.png" />
                <span class="hidden xl:block text-white text-lg ml-3">
                    KODLLIN<span class="font-medium"> </span>
                </span>
            </router-link>
            <!-- END: Logo -->
            <div class="side-nav__devider my-6"></div>
            <ul>
                <!-- BEGIN: First Child -->
                <template v-for="(menu, menuKey) in formattedMenu">
                    <li v-if="menu == 'devider'" :key="menu + menuKey" class="side-nav__devider my-6"></li>
                    <li v-else :key="menu + menuKey">
                        <template v-if="menu.roles.includes(userDetails.user_type)">

                            <SideMenuTooltip tag="a" :content="menu.title" href="javascript:;" class="side-menu" :class="{
    'side-menu--active': menu.active,
    'side-menu--open': menu.activeDropdown
  }" @click="linkTo(menu, router, $event)">
                                <div class="side-menu__icon">
                                    <component :is="menu.icon" />
                                </div>
                                <div class="side-menu__title">
                                    {{ menu.title }}
                                    <span class="text-xl px-1 font-bold text-theme-6 mr-1 ml-2 pt-2" v-if="menu.title == 'Process' && (screenData.reqCount > 0 || screenData.reqCountLot > 0)"> *</span>
                                    <div v-if="menu.subMenu" class="side-menu__sub-icon" :class="{ 'transform rotate-180': menu.activeDropdown }">
                                        <ChevronDownIcon />
                                    </div>
                                </div>
                            </SideMenuTooltip>
                            <!-- BEGIN: Second Child -->

                            <transition @enter="enter" @leave="leave">
                                <ul v-if="menu.subMenu && menu.activeDropdown">
                                    <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                                        <div v-if="subMenu.roles.includes(userDetails.user_type)">
                                            <SideMenuTooltip tag="a" :content="subMenu.title" href="javascript:;" class="side-menu" :class="{ 'side-menu--active': subMenu.active }" @click="linkTo(subMenu, router, $event)">
                                                <div class="side-menu__title">
                                                    {{ subMenu.title }}
                                                    <span class="text-xs px-1 rounded-full bg-theme-6 text-white mr-1 ml-2" v-if="subMenu.title == 'Issue Transactions' && screenData.reqCount > 0">{{screenData.reqCount}}</span>
                                                    <span class="text-xs px-1 rounded-full bg-theme-6 text-white mr-1 ml-2" v-if="subMenu.title == 'List Issue(Lot/Packet)' && screenData.reqCountLot > 0">{{screenData.reqCountLot}}</span>
                                                    <div v-if="subMenu.subMenu" class="side-menu__sub-icon" :class="{
              'transform rotate-180': subMenu.activeDropdown
            }">
                                                        <ChevronDownIcon />
                                                    </div>
                                                </div>
                                            </SideMenuTooltip>
                                            <!-- BEGIN: Third Child -->
                                            <transition @enter="enter" @leave="leave">
                                                <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                                                    <li v-for="(lastSubMenu,
            lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                                                        <SideMenuTooltip tag="a" :content="lastSubMenu.title" href="javascript:;" class="side-menu" :class="{ 'side-menu--active': lastSubMenu.active }" @click="linkTo(lastSubMenu, router, $event)">

                                                            <div class="side-menu__title">
                                                                {{ lastSubMenu.title }}
                                                            </div>
                                                        </SideMenuTooltip>
                                                    </li>
                                                </ul>
                                            </transition>
                                            <!-- END: Third Child -->
                                        </div>
                                    </li>
                                </ul>

                            </transition>
                            <!-- END: Second Child -->
                        </template>
                        <!-- END: Second Child -->
                    </li>
                </template>
                <!-- END: First Child -->
            </ul>
        </nav>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content">
            <TopBar />
            <router-view />
        </div>
        <!-- END: Content -->
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    computed,
    onMounted,
    ref,
    watch
} from 'vue'
import {
    useRoute,
    useRouter
} from 'vue-router'
import {
    useStore
} from '@/store'
import {
    helper as $h
} from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import {
    linkTo,
    nestedMenu,
    enter,
    leave
} from './index'

export default defineComponent({
    components: {
        TopBar,
        MobileMenu,
        DarkModeSwitcher,
        SideMenuTooltip
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const formattedMenu = ref([])
        const sideMenu = computed(() =>
            nestedMenu(store.state.sideMenu.menu, route)
        )

        const userDetails = computed(() => store.state.user.currentUser)
        const menuData = computed(() => store.state.user.sideMenuview)

        watch(
            computed(() => route.path),
            () => {
                formattedMenu.value = $h.toRaw(sideMenu.value)
            }
        )

        onMounted(() => {
            cash('body')
                .removeClass('error-page')
                .removeClass('login')
                .addClass('main')
            formattedMenu.value = $h.toRaw(sideMenu.value)
        })

        return {
            formattedMenu,
            router,
            linkTo,
            enter,
            leave,
            userDetails,
            menuData
        }
    }
})
</script>
